import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { ApiService } from '../../../shared/service/api.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, FormControlName } from '@angular/forms';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faBars = faBars;
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public infoForm: UntypedFormGroup;
  search: null;
  itemsPerPage: null
  page: null
  filter_gender: null
  filter_social_situation: null
  filter_nationality: null
  filter_ageFrom: null
  filter_ageTo: null
  filter_marriage: null
  filter_entry: null
  filter_level: null
  filter_polygamy: null
  filter_foreign: null
  filter_qualification: null;
  user_name: null;
  user_phone: null;
  loading = true;
  public home_data: any;
  count: number;
  no_data = false;
  no_results = false;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  constructor(public navServices: NavService, public ApiService: ApiService, private formBuilder: UntypedFormBuilder, private _router: Router) { }

  ngOnInit() {
    this.infoForm = this.formBuilder.group({
      search: [''],
    })
  }


  submit_search(event: any) {
    let search_val = event.target.value;
    this.ApiService.search_input(search_val).subscribe((data: any) => {

    })

  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }
  logout(){
    localStorage.setItem('login', '0');
    this._router.navigate(['auth/login']);
  }
}
