<div class="page-main-header" [class.open]="open">
    <div class="main-header-right d-flex">
        <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
                <a [routerLink]="'/dashboard/'">
                  <h1 class="logo_word">KAYAN</h1>
                  <h2 class="kyan_org">مؤسسة كيان</h2>
                </a>
              </div>
        </div>
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <div class="switch">
                    <a>
                        <div id="sidebar-toggle" (click)="collapseSidebar()">
                            <fa-icon [icon]="faBars"></fa-icon>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="nav-right col d-flex justify-content-end">
            <button (click)="logout()" class="logout"><i class="fa fa-sign-out ml_5" aria-hidden="true"></i> تسجيل الخروج</button>
            
        </div>
    </div>
</div>