import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;

  validation:string;
  constructor(private formBuilder: UntypedFormBuilder, private _router: Router) {

  }

  submitLogin() {
    if(this.emailInput.nativeElement.value == 'e.elroby97@gmail.com' && this.passwordInput.nativeElement.value == '***2031***'){
      localStorage.setItem('login', '1');
      this.validation = '';
      this._router.navigate(['dashboard']);
    }else if(this.emailInput.nativeElement.value == 'ahmedramadan.net@gmail.com' && this.passwordInput.nativeElement.value == '123456'){
      localStorage.setItem('login', '1');
      this.validation = '';
      this._router.navigate(['dashboard']);
    }
    else if(this.emailInput.nativeElement.value == 'wwwKyan97@gmail.com' && this.passwordInput.nativeElement.value == '***2031***'){
      localStorage.setItem('login', '2');
      this.validation = '';
      this._router.navigate(['dashboard']);
    }else{
      this.validation = 'يوجد خطأ في البريد الالكتروني او كلمة المرور';
      localStorage.setItem('login', '0');
      return false;
    }
  }



  // createLoginForm() {
  //   this.loginForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //   });
  // }

  ngOnInit() {
    if(localStorage.getItem('login') == '1' || localStorage.getItem('login') == '2' ){
      this._router.navigate(['dashboard']);
    }
  }



}
