import { Component, ElementRef, HostListener, OnInit, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  faCoffee = faCoffee;

  modalRef?: BsModalRef;

  public form_data: any;
  public user_branches: any;
  public user_genders: any;
  public user_marriage_types: any;
  public user_payment_statuses: any;
  public user_subscription_types: any;
  public user_nationalities: any;
  public user_social_situations: any;
  public user_entry_statuses: any;
  public user_religions: any;
  public user_levels: any;
  public user_woman_social_situations: any;
  public user_qualification: any;

  show_gender = 0;

  infoForm: UntypedFormGroup;
  infoForm_candidates: UntypedFormGroup;

  public product_list = [];
  public home_data: any;
  public user_data: any;
  public candidates_data: any;
  closeResult = '';
  id = '';
  show_pop = false;
  filtering_pop = false;
  page = 1;
  count: number;
  totalItems: number;
  data: any;
  itemsPerPage = 52;
  loading = true;
  branch_view: any;

  woman_social_situations: any;
  qualification_filter: any;
  gender: any;
  social_situation: any;
  nationality: any;
  req_age_from: any;
  req_age_to: any;
  qualification: any;
  marriage_type: any;
  entry_status: any;
  level: any;
  polygamy: any;
  foreign: any;
  nationality_used = false;
  no_data = false;
  more_one: any;
  not_egy: any;
  user_name: null;
  user_phone: null;

  filter_btn = true;
  no_results = false;

  filter_gender = '';
  filter_social_situation = '';
  filter_nationality = '';
  filter_ageFrom = '';
  filter_ageTo = '';
  filter_marriage = '';
  filter_entry = '';
  filter_level = '';
  filter_polygamy = '';
  filter_foreign = '';
  filter_qualification = '';
  search: '';

  get_current_gender: any;
  current_user_id:any;

  // api_link = 'https://api.kayanzawaj.com/api/';
  _url = "https://api.kayanzawaj.com/api/proposals?"
  user_gender: any;
  candidates_name: '';


  @Output() search_by_phone = new EventEmitter();

  constructor(public ApiService: ApiService, private http: HttpClient, private formBuilder: UntypedFormBuilder, private modalService: BsModalService, private _router: Router) {


  }



  ngOnInit() {
    if(localStorage.getItem('login') == '0'){
      this._router.navigate(['auth/login']);
    }else{
      
    }
    if (this.search == undefined) {
      this.search = '';
    }

    this.ApiService.filter_req(this._url,
      this.itemsPerPage,
      this.page,
      this.filter_gender,
      this.filter_social_situation,
      this.filter_nationality,
      this.filter_ageFrom,
      this.filter_ageTo,
      this.filter_marriage,
      this.filter_entry,
      this.filter_level,
      this.filter_polygamy,
      this.filter_foreign,
      this.filter_qualification,
      this.search
    ).subscribe(result => {
      this.loading = false;
      this.home_data = result['data'];
      this.count = result['meta'].total;
    });

    this.ApiService.form_data().subscribe(result => {
      this.user_branches = result['records']['branches'];
      this.user_genders = result['records']['genders'];
      this.user_marriage_types = result['records']['marriage_types'];
      this.user_payment_statuses = result['records']['payment_statuses'];
      this.user_subscription_types = result['records']['subscription_types'];
      this.user_nationalities = result['records']['nationalities'];
      this.user_social_situations = result['records']['social_situations'];
      this.user_entry_statuses = result['records']['entry_statuses'];
      this.user_religions = result['records']['religions'];
      this.user_levels = result['records']['levels'];
      this.user_woman_social_situations = result['records']['women_social_situations'];
      this.user_qualification = result['records']['qualifications'];

    });

    this.infoForm = this.formBuilder.group({
      gender: [''],
      nationality: [''],
      req_age_from: [''],
      req_age_to: [''],
      qualification: [''],
      social_situation: [''],
      marriage_type: [''],
      entry_status: [''],
      level: [''],
      polygamy: [''],
      foreign: [''],
      search: ['']
    });

    this.infoForm_candidates = this.formBuilder.group({
      gender: [''],
      candidates_name: ['']
    });


  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('modal-xl');
  }
  openCandidatesModal(candidates: TemplateRef<any>, current_gender, current_user_id) {
    this.get_current_gender = current_gender == 1 ? 2 : 1;
    this.current_user_id = current_user_id
    this.modalRef = this.modalService.show(candidates);
    this.modalRef.setClass('modal-xl');
  }
  get_all_users() {
    

    if (this.candidates_name == undefined || this.candidates_name == '' || this.candidates_name == null) {
      this.candidates_data = [];
      console.log('ppp');
    } else {

      this.ApiService.user_candidates(
        this.get_current_gender,
        this.candidates_name,
      ).subscribe((data: any) => {
        this.loading = false;
        this.candidates_data = data['data'];
      })
    }
  }
  candidate_this(event, candidate_user_id){
    let candite_data = {
      'proposal': this.current_user_id,
      'candidate':candidate_user_id
    }
    this.ApiService.select_user_candidates(candite_data).subscribe((data: any) => {
      event = false
      document.getElementById(candidate_user_id).remove()
    })
  }
  change_gender(e) {
    this.filter_gender = e.target.value;
    this.show_gender = e.target.value;
    this.filter_btn = false;

    if (this.show_gender == 1) {
      this.show_gender == 1
    } else {
      this.show_gender == 2
    }
  }
  nationality_filter(nationality) {
    this._url += `&nationality=${nationality}`
  }
  open(id) {
    this.show_pop = true;
    this.id = id;

    this.ApiService.user_select(this.id).subscribe(result => {
      this.user_data = result['data']['proposal'];

    });
  }
  close_pop(event) {
    this.show_pop = false;
    this.filtering_pop = false;
    event.preventDefault();
  }
  filtering(event) {
    this.filtering_pop = true;
    event.preventDefault();
  }
  handlePageChange(event) {
    this.page = event;
  }
  getPage(event) {
    this.page = event;
    this.loading = true;
    window.scroll(0, 0);

    if (this.search == undefined) {
      this.search = '';
    }
    const options = { params: new HttpParams({fromString: "page=1&itemsPerPage=20"}) };
    this.ApiService.filter_req(this._url,
      this.itemsPerPage,
      this.page,
      this.filter_gender,
      this.filter_social_situation,
      this.filter_nationality,
      this.filter_ageFrom,
      this.filter_ageTo,
      this.filter_marriage,
      this.filter_entry,
      this.filter_level,
      this.filter_polygamy,
      this.filter_foreign,
      this.filter_qualification,
      this.search
    ).subscribe((data: any) => {
      this.loading = false;
      this.home_data = data['data'];
      this.count = data['meta']['total'];
    })
  }
  pageChanged(event){
    console.log(event);
  }
  search_fu(event) {
    this.search = event.target.value;
    if (this.search != '') {
      this.filter_btn = false;
    }

  }
  applyFilter_social_situation(event) {
    this.filter_social_situation = event;
    this.filter_btn = false;
  }
  applyFilter_nationality(event) {
    this.filter_nationality = event;
    this.filter_btn = false;
  }
  applyFilter_qualification(event) {
    this.filter_qualification = event;
    this.filter_btn = false;
  }
  fromFromO(e) {
    this.filter_ageFrom = e.target.value;
    this.filter_btn = false;
  }
  fromTO(e) {
    this.filter_ageTo = e.target.value;
    this.filter_btn = false;
  }
  change_marriage(event) {
    this.filter_marriage = event;
    this.filter_btn = false;
  }
  change_entry(event) {
    this.filter_entry = event;
    this.filter_btn = false;
  }
  change_level(event) {
    this.filter_level = event;
    this.filter_btn = false;
  }
  applyFilter_more_one(event) {
    this.filter_polygamy = event.target.value;
  }
  applyFilter_not_egy(event) {
    this.filter_foreign = event.target.value;
  }
  submit_filter() {
    this.page = 1;
    if (this.search == undefined) {
      this.search = '';
    }

    this.ApiService.filter_req(this._url,
      this.itemsPerPage,
      this.page,
      this.filter_gender,
      this.filter_social_situation,
      this.filter_nationality,
      this.filter_ageFrom,
      this.filter_ageTo,
      this.filter_marriage,
      this.filter_entry,
      this.filter_level,
      this.filter_polygamy,
      this.filter_foreign,
      this.filter_qualification,
      this.search
    ).subscribe((data: any) => {
      console.log(this.itemsPerPage);
      this.loading = false;
      this.home_data = data['data'];
      this.count = data['meta']['total'];
      if (this.home_data.length < 1) {
        this.no_data = true;
      }
      if (this.home_data.length == 0) {
        this.no_results = true;
      } else {
        this.no_results = false;
      }
    })
  }

}
