<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-12 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <div class="logo-wrapper text-center">
                                <div>
                                    <h1 class="logo_word text-center">KYAN</h1>
                                    <h2 class="kyan_org">مؤسسة كيان</h2>
                                </div>
                            </div>
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>تسجيل الدخول</ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form class="form-horizontal auth-form">                                               
                                                <div class="form-group">
                                                    <input #emailInput type="email" class="form-control" placeholder="البريد الالكتروني"/>
                                                </div>
                                                <div class="form-group">
                                                    <input #passwordInput type="password" class="form-control" placeholder="كلمة المرور"/>
                                                </div>

                                                <div class="form-button d-flex justify-content-center">
                                                    <a (click)="submitLogin()" class="btn btn-primary login-btn">
                                                        <i class="fa fa-sign-in ml_5" aria-hidden="true"></i> تسجيل الدخول
                                                    </a>
                                                </div>
                                                <div>
                                                    <h5 class="validation">{{validation}}</h5>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>