import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'add-user',
    loadChildren: () => import('../../components/kyan/add-user/add-man.module').then(m => m.AddManModule),
  },
  {
    path: 'view-user/:id',
    loadChildren: () => import('../../components/kyan/view-user/view-user.module').then(m => m.ViewUserModule),
  },

 
];