import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api_link = 'https://api.kayanzawaj.com/api/';
  

  checkSubject = new Subject<any>();

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'responseType': 'json',
    'POST': '/api/proposals HTTP/1.1',
    'Accept': 'application/json, text/plain, */*'

  });

  constructor(private http: HttpClient) { }

  // public home_page(url, per_page, page) {
  //   return this.http.get(url + `per_page=${per_page}&page=${page}`);
  // }

  public home_page(url, per_page, page, gender, status, nationality, ageFrom, ageTo, marriage, entry, level, polygamy, foreign, qualification, search) {
    let params = new HttpParams()
    .set('page', page)
    .set('per_page', per_page)
    .set('gender', gender)
    .set('status', status)
    .set('nationality', nationality)
    .set('ageFrom', ageFrom)
    .set('ageTo', ageTo)
    .set('marriage', marriage)
    .set('entry', entry)
    .set('level', level)
    .set('polygamy', polygamy)
    .set('foreign', foreign)
    .set('qualification', qualification)
    .set('search', search)
    return this.http.get(url, {params});
  }

  public filter_req(url, per_page, page, gender, status, nationality, ageFrom, ageTo, marriage, entry, level, polygamy, foreign, qualification, search) {
    let params = new HttpParams()
    .set('page', page)
    .set('per_page', per_page)
    .set('gender', gender)
    .set('status', status)
    .set('nationality', nationality)
    .set('ageFrom', ageFrom)
    .set('ageTo', ageTo)
    .set('marriage', marriage)
    .set('entry', entry)
    .set('level', level)
    .set('polygamy', polygamy)
    .set('foreign', foreign)
    .set('qualification', qualification)
    .set('search', search)
    return this.http.get(url, {params});
  }
 c
  public user_candidates(gender, name) {
    let params = new HttpParams()
    .set('gender', gender)
    .set('name', name)
    .set('flag', "candidates")
    return this.http.get(this.api_link + `proposals?`, {params});
  }
  public select_user_candidates(data) {    
    return this.http.post(this.api_link + `candidates`, data);
  }
  public delete_user_candidates(candidate) {
    return this.http.delete(this.api_link + `candidates/${candidate}`);
  }
  public edit_user_candidates(id, data) {
    return this.http.put(this.api_link + `candidates/${id}`, data);
  }
  public search_input(phone) {
    let params = new HttpParams()
    .set('phone', phone)
    return this.http.get(this.api_link + `proposals?`, {params});
  }
  public home_filter(filter) {
    return this.http.get(this.api_link + `proposals?per_page` + filter);
  }
  public user_select(id) {
    return this.http.get(this.api_link + `proposals/${id}`);
  }
  public form_data() {
    return this.http.get(this.api_link + `proposals/data`);
  }
  public proposal_form(data) {    
    return this.http.post(this.api_link + `proposals`, data);
  }
  public proposal_update(id,data) {    
    return this.http.post(this.api_link + `proposals/${id}?_method=PUT`, data);
  }
  public filter_data(id) {
    let param_nationality = new HttpParams().set('nationality', id) 
    return this.http.get(this.api_link + `proposals/data`, {params: param_nationality});
  }

  public delete_proposal(id) {
    return this.http.delete(this.api_link + `proposals/${id}`);
  }
  public get_pagi(url) {
    return this.http.get(url);
  }


  // candidates

  public get_client_candidates(id) {
    let candidates = new HttpParams().set('proposal', id) 
    return this.http.get(this.api_link + `candidates`, {params: candidates});
  }
  
}
