<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper">
    <a [routerLink]="'/dashboard/'">
      <h1 class="logo_word">KAYAN</h1>
      <h2 class="kyan_org">مؤسسة كيان</h2>
    </a>
  </div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
    <h6 class="mt-3 f-14">{{username}}</h6>
    <p>{{position}}</p>
  </div>
  <ul class="sidebar-menu">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">

      <!-- Link -->
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-header"
        *ngIf="menuItem.type === 'link'">
        <!-- <fa-icon [icon]="menuItem.icon"></fa-icon> -->
        <span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>


    </li>
  </ul>
</div>