<div class="container-fluid">

    <form [formGroup]="infoForm" (ngSubmit)="submit_filter()" class="filter_form">

        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div class="d-flex w100">
                        <label for="search" class="col-form-label pt-0 label_search"> <i class="fa fa-filter ml_5"
                                aria-hidden="true"></i> بحث</label>
                        <input id="search" [(ngModel)]="search" class="search_input form-control"
                            formControlName="search" placeholder=" البحث بالاسم او رقم الهاتف ٫٫٫" type="text"
                            (change)="search_fu($event)" value="">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="gender" class="col-form-label pt-0"> النوع</label>
                    <select id="gender" formControlName="gender" [(ngModel)]="gender" class="custom-select"
                        name="gender" (change)="change_gender($event)">
                        <option value="">-</option>
                        <option *ngFor="let gender of user_genders; let itemIndex = index" [selected]="itemIndex == 0"
                            value="{{gender.id}}">{{gender.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12" *ngIf="show_gender != 2">
                <div class="form-group">
                    <label for="social_situation" class="col-form-label pt-0"> الحالة</label>
                    <select id="social_situation" formControlName="social_situation" [(ngModel)]="social_situation"
                        class="custom-select" name="social_situation"
                        (change)="applyFilter_social_situation(social_situation)">
                        <option value="">-</option>
                        <!-- <option selected disabled>-</option> -->
                        <option *ngFor="let social_situations of user_social_situations"
                            value="{{social_situations.id}}">{{social_situations.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12" *ngIf="show_gender == 2">
                <div class="form-group">
                    <label for="social_situation" class="col-form-label pt-0"> الحالة</label>
                    <select id="social_situation" formControlName="social_situation" [(ngModel)]="social_situation"
                        class="custom-select" name="social_situation"
                        (change)="applyFilter_social_situation(social_situation)">
                        <option value="">-</option>
                        <!-- <option selected disabled>-</option> -->
                        <option *ngFor="let woman_social_situations of user_woman_social_situations"
                            value="{{woman_social_situations.id}}">{{woman_social_situations.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="nationality" class="col-form-label pt-0"> الجنسية</label>
                    <select id="nationality" class="custom-select" name="nationality" formControlName="nationality"
                        [(ngModel)]="nationality" (change)="applyFilter_nationality(nationality)">
                        <option value="">-</option>
                        <option *ngFor="let nationality of user_nationalities" value="{{nationality.id}}">
                            {{nationality.name_ar}}</option>

                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="validationCustom01" class="col-form-label pt-0"> العمر</label>
                    <div class="d-flex age_con">
                        <input id="req_age_from" type="text" name="req_age_from" class="form-control" placeholder="من"
                            formControlName="req_age_from" [(ngModel)]="req_age_from" (change)="fromFromO($event)">
                        <input id="req_age_to" type="text" name="req_age_to" class="form-control" placeholder="الي"
                            formControlName="req_age_to" [(ngModel)]="req_age_to" (change)="fromTO($event)">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="qualification" class="col-form-label pt-0"> المؤهل</label>
                    <select id="qualification" formControlName="qualification" [(ngModel)]="qualification"
                        class="custom-select" name="qualification" (change)="applyFilter_qualification(qualification)">
                        <option value="">-</option>
                        <option *ngFor="let qualification_filter of user_qualification"
                            value="{{qualification_filter.id}}">
                            {{qualification_filter.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="marriage_type" class="col-form-label pt-0"> نوع الزواج </label>
                    <select id="marriage_type" class="custom-select" name="marriage_type"
                        formControlName="marriage_type" [(ngModel)]="marriage_type"
                        (change)="change_marriage(marriage_type)">
                        <option value="">-</option>
                        <option *ngFor="let marriage_types of user_marriage_types" value="{{marriage_types.id}}">
                            {{marriage_types.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="entry_status" class="col-form-label pt-0"> حالة القيد</label>
                    <select id="entry_status" formControlName="entry_status" [(ngModel)]="entry_status"
                        class="custom-select" name="entry_status" (change)="change_entry(entry_status)">
                        <!-- <option selected disabled>-</option> -->
                        <option value="">-</option>
                        <option *ngFor="let statuses of user_entry_statuses" value="{{statuses.id}}">
                            {{statuses.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group">
                    <label for="level" class="col-form-label pt-0"> المستوي</label>
                    <select id="level" formControlName="level" [(ngModel)]="level" class="custom-select" name="level"
                        (change)="change_level(level)">
                        <option value="">-</option>
                        <option *ngFor="let level of user_levels" value="{{level.id}}">{{level.name_ar}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group" *ngIf="show_gender == 2">
                    <label for="polygamy" class="col-form-label pt-0"> هل تقبلين التعدد</label>
                    <select id="polygamy" formControlName="polygamy" [(ngModel)]="polygamy" class="custom-select"
                        name="polygamy" (change)="applyFilter_more_one($event)">
                        <option value="">-</option>
                        <option value="1">نعم</option>
                        <option value="2">لا</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="form-group" *ngIf="show_gender == 2">
                    <label for="foreign" class="col-form-label pt-0">تقبلي غير مصري</label>
                    <select id="foreign" formControlName="foreign" [(ngModel)]="foreign" class="custom-select"
                        name="foreign" (change)="applyFilter_not_egy($event)">
                        <option value="">-</option>
                        <option value="1">نعم</option>
                        <option value="2">لا</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12"></div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12" [ngClass]="{'disable_btn': filter_btn == true}">
                <div class="form-group">
                    <label for="validationCustom01" class="col-form-label pt-0 no_data"> &nbsp;</label>
                    <button type="submit" class="filter_submit">بحث</button>
                </div>
            </div>
        </div>

    </form>

    <div class="" *ngIf="!loading">
        <div class="row products-admin ratio_asos">
            <div class="users_count">عدد النتائج : {{count}}</div>
            <div class="filter_form" *ngIf="no_results">
                <h4>لا يوجد بيانات</h4>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                *ngFor="let user of home_data | paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems: count}">
                <ng-template #template>
                    <div class="modal-header model_{{user.entry_status}}">
                        <h4 class="modal-title pull-left">الصورة الشخصية : {{user.name}}</h4>
                    </div>
                    <div class="modal-body d-flex justify-content-center">
                        <img [src]="user_data?.photo" class="img-fluid blur-up lazyload bg-img pop_img" alt="">
                    </div>
                </ng-template>
                <ng-template [ngIf]="user.entry_status == 1">
                    <span class="status home_status" [ngClass]="{'user_active':  user.entry_status == '1'}">فعال</span>
                </ng-template>
                <ng-template [ngIf]="user.gender == 1 ">
                    <ng-template [ngIf]="user.entry_status == 2 ">
                        <span class="status home_status"
                            [ngClass]="{'was_married':  user.entry_status == '2'}">تزوج</span>
                    </ng-template>
                </ng-template>
                <ng-template [ngIf]="user.gender == 2 ">
                    <ng-template [ngIf]="user.entry_status == 2 ">
                        <span class="status home_status"
                            [ngClass]="{'was_married':  user.entry_status == '2'}">تزوجت</span>
                    </ng-template>
                </ng-template>
                <ng-template [ngIf]="user.entry_status == 3 ">
                    <span class="status home_status" [ngClass]="{'was_canceled':  user.entry_status == '3'}">تم
                        إلغاءه</span>
                </ng-template>
                <ng-template [ngIf]="user.entry_status == 4 ">
                    <span class="status home_status" [ngClass]="{'user_pause':  user.entry_status == '4'}">إيقاف
                        مؤقت</span>
                </ng-template>
                <ng-template [ngIf]="user.entry_status == 5 ">
                    <span class="status home_status"
                        [ngClass]="{'expired_subscription':  user.entry_status == '5'}">اشتراك
                        منتهى</span>
                </ng-template>

                <div class="card">
                    <div class="card-body product-box">
                        <div class="img-wrapper" (click)="open(user.id)">

                            <div class="front" (click)="openModal(template)">
                                <a href="javascript:void(0)" class="home_card_photo">
                                    <img [src]="[user.photo]" class="blur-up lazyload bg-img user_card_img" alt=""
                                        onerror="img_error = true; this.src='assets/images/dashboard/no_image.png'" /></a>

                                <div class="product-hover">

                                </div>
                            </div>
                        </div>

                        <div class="product-detail">
                            <div class="d-flex justify-content-between">
                                <div>{{user.name}}</div>
                                <div *ngIf="user.branch">
                                    <ng-template [ngIf]="user.branch == 1 ">
                                        <span>كيان</span>
                                    </ng-template>
                                    <ng-template [ngIf]="user.branch == 2 ">
                                        <span>مكة</span>
                                    </ng-template>
                                    <ng-template [ngIf]="user.branch == 3 ">
                                        <span>الأمل</span>
                                    </ng-template>
                                    <ng-template [ngIf]="user.branch == 4 ">
                                        <span>فيسبوك</span>
                                    </ng-template>
                                </div>
                                <div *ngIf="user.branch == null"> لا يوجد بيانات للفرع</div>
                                <!-- <div *ngIf="user.branch == '[object Object]'">مؤسسة كيان</div> -->
                            </div>
                            <div class="user_req">{{user.details | slice:0:100}} ...</div>
                            <div class="height42">
                                <div class="row">
                                    <div class="col-4 limit_text_100" title="{{user.social_situation}}">

                                        <ng-template [ngIf]="user.gender == 1 ">
                                            <ng-template [ngIf]="user.social_situation == 1 ">
                                                <span>أعزب</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 2 ">
                                                <span>أرمل</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 3 ">
                                                <span>مطلق</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 4 ">
                                                <span>مطلق كتب كتاب</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 5 ">
                                                <span>متزوج</span>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template [ngIf]="user.gender == 2 ">
                                            <ng-template [ngIf]="user.social_situation == 1 ">
                                                <span>انسه</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 2 ">
                                                <span>أرملة</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 3 ">
                                                <span>مطلقة</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 4 ">
                                                <span>مطلقة كتب كتاب</span>
                                            </ng-template>
                                            <ng-template [ngIf]="user.social_situation == 5 ">
                                                <span>متزوجة</span>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="col-4 text-center" title="{{user.age}}">{{user.age}} سنه</div>
                                    <div class="col-4 text-left limit_text_100" title="{{user.qualification}}">
                                        <ng-template [ngIf]="user.qualification == 1 ">
                                            <span>متوسط</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.qualification == 2 ">
                                            <span>عالي</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.qualification == 3 ">
                                            <span>بدون مؤهل</span>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="limit_text_100 col-4 " title="{{user.work}}">{{user.work}}</div>
                                    <div class="limit_text col-4 text-center" title="{{user.living_location}}">
                                        {{user.living_location}}</div>
                                    <div class="col-4 text-left limit_text_100" title="{{user.nationality}}">

                                        <ng-template [ngIf]="user.nationality == 1 ">
                                            <span> مصري</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.nationality == 2 ">
                                            <span> عربي</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.nationality == 3 ">
                                            <span> أجنبي</span>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <a class="filtering_btn open_filtering_btn"
                                    (click)="openCandidatesModal(candidates, user.gender, user.id)"> <i
                                        class="fa fa-group ml_5" aria-hidden="true"></i> ترشيح</a>
                                <a class="filtering_btn view_btn" [routerLink]="['/view-user', user.id]" target="_blank"> <i
                                        class="fa fa-eye ml_5" aria-hidden="true"></i> عرض</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center no_data" *ngIf="no_data"> لا يوجد بيانات</div>
            <div class="col-12">
                <div class="pagination_con">
                    <pagination-controls (pageChange)="getPage(page = $event)" previousLabel="السابق" nextLabel="التالي"
                        responsive="true"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" *ngIf="loading">جاري التحميل ٫٫٫٫</div>


    <!-- Container-fluid starts-->
   


    <ng-template #candidates>
        <div class="modal-header">
            <h4 class="modal-title pull-left"> <i class="fa fa-group" aria-hidden="true"></i> الترشيحات </h4>
        </div>
        <div class="modal-body d-flex justify-content-center">
            <form [formGroup]="infoForm_candidates" class="w-100">

                <input [(ngModel)]="candidates_name" class="search_input form-control" formControlName="candidates_name"
                    placeholder=" البحث بالاسم ٫٫٫" type="text" (keyup)="get_all_users()" value="">

                <ul>
                    <li *ngFor="let user of candidates_data" class="candidates_data">
                        <div class="d-flex link_this align-items-center" (click)="candidate_this($event, user.id)"
                            id="{{user.id}}">
                            <div class="candidate_img">
                                <img [src]="user.photo" alt="">
                            </div>
                            <div class="w-full">
                                <h3>{{user.name}}</h3>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div class="data_space"> العمر: {{user.age}} - </div>
                                        <div class="data_space">الحالة الاجتماعية:
                                            <ng-template [ngIf]="user.gender == 1 ">
                                                <ng-template [ngIf]="user.social_situation == 1 ">
                                                    <span>أعزب</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 2 ">
                                                    <span>أرمل</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 3 ">
                                                    <span>مطلق</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 4 ">
                                                    <span>مطلق كتب كتاب</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 5 ">
                                                    <span>متزوج</span> &nbsp; - &nbsp;
                                                </ng-template>
                                            </ng-template>
                                            <ng-template [ngIf]="user.gender == 2 ">
                                                <ng-template [ngIf]="user.social_situation == 1 ">
                                                    <span>انسه</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 2 ">
                                                    <span>أرملة</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 3 ">
                                                    <span>مطلقة</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 4 ">
                                                    <span>مطلقة كتب كتاب</span> &nbsp; - &nbsp;
                                                </ng-template>
                                                <ng-template [ngIf]="user.social_situation == 5 ">
                                                    <span>متزوجة</span> &nbsp; - &nbsp;
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <div class="data_space">المؤهل:
                                            <ng-template [ngIf]="user.qualification == 1 ">
                                                <span>متوسط</span> &nbsp; - &nbsp;
                                            </ng-template>
                                            <ng-template [ngIf]="user.qualification == 2 ">
                                                <span>عالي</span> &nbsp; - &nbsp;
                                            </ng-template>
                                            <ng-template [ngIf]="user.qualification == 3 ">
                                                <span>بدون مؤهل</span> &nbsp; - &nbsp;
                                            </ng-template>
                                        </div>
                                        <div class="data_space">الاقامة: {{user.residence}} -
                                            <ng-template [ngIf]="user.residence == null ">
                                                <span>لا يوجد</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="data_space">
                                        <ng-template [ngIf]="user.entry_status == 1">
                                            <span class="status home_status"
                                                [ngClass]="{'user_active':  user.entry_status == '1'}">فعال</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.gender == 1 ">
                                            <ng-template [ngIf]="user.entry_status == 2 ">
                                                <span class="status home_status"
                                                    [ngClass]="{'was_married':  user.entry_status == '2'}">تزوج</span>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template [ngIf]="user.gender == 2 ">
                                            <ng-template [ngIf]="user.entry_status == 2 ">
                                                <span class="status home_status"
                                                    [ngClass]="{'was_married':  user.entry_status == '2'}">تزوجت</span>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template [ngIf]="user.entry_status == 3 ">
                                            <span class="status home_status"
                                                [ngClass]="{'was_canceled':  user.entry_status == '3'}">تم
                                                إلغاءه</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.entry_status == 4 ">
                                            <span class="status home_status"
                                                [ngClass]="{'user_pause':  user.entry_status == '4'}">إيقاف
                                                مؤقت</span>
                                        </ng-template>
                                        <ng-template [ngIf]="user.entry_status == 5 ">
                                            <span class="status home_status"
                                                [ngClass]="{'expired_subscription':  user.entry_status == '5'}">اشتراك
                                                منتهى</span>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </ng-template>
</div>
<!-- Container-fluid Ends-->